import Vue from "vue";
import Router from "vue-router";

import Home from "../src/pages/Home.vue";
import Support from "../src/pages/Support.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/support",
      name: "Support",
      component: Support,
    }
  ],
});

export default router;
