<template>
  <div class="bg-black">
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export default {
  name: "app",
};
</script>