<template>
  <div id="support" style="height:100vh;">
    <p class="text-center text-white m-8">Email <a target="_blank" href="mailto:hey@lapse.com">hey@lapse.com</a> for support</p>
  </div>
</template>

<script>
export default {
  name: "Support",
};
</script>
