<template>
  <div id="home" style="height:100vh;">
    <p>Redirecting to <a href="https://lapse.com">lapse.com</a></p>
  </div>
</template>

<script>
export default {
  name: "Home",
  mounted: () => {
    // Redirect with js here as netlift external redirects won't
    // work without registering aliases
    window.location.href = 'https://lapse.com';
  },
};
</script>
